<template>
  <div class="order-management">
    <pageBread
      :img="() => require('../../assets/image/zhuye.png')"
      text="领取优惠券"
    ></pageBread>
    <div>
      <div class="title">
        <div>
          <input v-model.trim="oddNumber" type="text" placeholder="请输入优惠券代码" onkeyup="this.value=this.value.replace(/\s+/g,'')">
        </div>
        <div style="text-align:right">
          <button class="nq-button" @click="search">领取</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { youhui } from "../../utils/apis/apis.js";
export default {
  name: 'parcel',
  data() {
    return {
      oddNumber: '',
      frameList: {
        oddNumber: '',
        weight: '',
        createTime: '',
        stockDays: '',
        note: ''
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 查询
    search() {
      this.$reqGet(youhui.list + "?couponCode=" + this.oddNumber).then((res) => {
        if (res.data.code === 200) {
          this.$toast.success(res.data.data)
          this.oddNumber = ''
        } else {
          this.$toast.error(res.data.message)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import url("../order-management/order.less");
/deep/ .title{
  background: white;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}
// .title select{
//   width: 20%;
//   height: 40px;
//   padding-left: 8px;
//   margin: 10px 0;
//   // background: white;
//   border-right: none;
// }
.title input{
  height: 40px;
  // width: 100%;
  padding: 15px;
  margin: 10px 0;
  box-sizing: border-box;
}
/deep/ .nq-button{
  // display: none;
  height: 40px;
  padding: 10px 20px;
  margin: 10px 0;
  margin-left: 15px;
  border-radius: 5px;
}
/deep/ .baoguo{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 5px;
  margin-top: 20px;
}
// /deep/ .flex h3{
//   padding: 5px;
//  border-bottom: 1px solid #cdcdcd;
// }
/deep/ .copyLi-button{
  padding: 5px 12px;
  // border-radius: 15px;
  background: rgb(248, 132, 0);
  margin-top: 15px;
}
/deep/ .image{
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #cdcdcd;
}
/deep/ .ware-house-item {
  margin-bottom: 8px;

  display: flex;
  span:nth-child(1) {
    width: 38%;
  }
  span:nth-child(2) {
    flex: 1;
  }
}
/deep/ .btn{
  text-align: center;
}
</style>
